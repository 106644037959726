/* ==========================================================================
   Vendor: react-paginate
   ========================================================================== */

/**
 * Custom styles for:
 * https://github.com/AdeleD/react-paginate
 */

.pagination {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;

  .previous a,
  .next a {
    @extend .button, .button--base-plain;
  }

  li.disabled a {
    @extend .disabled;
  }
}

.pages__page {
  @extend .button, .button--base-plain;

  .active & {
    @extend .button--active, .button--base-raised-semidark;
  }

  .break & {
    opacity: 1;
    pointer-events: none;
  }
}
