/* ==========================================================================
   Global: page
   ========================================================================== */

.page {
  display: grid;
  min-height: 100vh;
  grid-template-rows: 3rem auto;

  //@include media(large-up) {
  //  display: grid;
  //  grid-template-columns: ($global-spacing * 4) auto 0;
  //}
}

/* Header
   ========================================================================== */

.page__header {
  @extend .antialiased;
  background: $base-color;
  color: #fff;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  // overflow: hidden;
  z-index: 1000;
  height: fit-content;

  //@include media(large-up) {
  //  position: sticky;
  //  top: 0;
  //  left: 0;
  //  bottom: 0;
  //  flex-flow: column nowrap;
  //  height: 100vh;
  //  padding: $global-spacing;
  //}
}

.page__headline {
  > *:last-child {
    margin-bottom: 0;
  }
}

.page__hamburger {
  display: table-caption;
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;

  @include media(large-up) {
    width: 2.5rem;
    font-size: 1.25rem;
    padding-top: $global-spacing * 0.25;
  }

  a {
    display: block;
    color: inherit;

    &::before {
      @extend %collecticon-hamburger-menu;
    }
  }

  color: white;
  padding-right: 1rem;
  padding-top: 0.75rem;
  font-size: 1.5rem;
  font-size: 1.5rem;
  align-self: flex-start;
  margin-left: auto;

  span {
    @extend .visually-hidden;
  }
}

.page__prime-nav {
  display: flex;
  margin: 0 0 0 0;
  min-width: 100vw;
  @media (max-width: 1023px) {
    & img.narrow-logo {
      display: none !important;
    }
    & img.full-logo {
      display: block !important;
    }
    &.nav-collapsed {
      & .global-menu {
        display: none;
      }
    }
    &.nav-expanded {
      & .global-menu {
        flex-direction: column;
        align-items: start;
        ul,
        li {
          display: block;
        }
      }
    }
  }
}

/* Global menu */

.global-menu__container {
  display: flex;
  justify-content: start;
  width: 100%;
  align-items: center;
}

.global-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;

  > * {
    margin: 0 0 0 ($global-spacing * 0.25);
  }
}

.global-menu.extras {
  flex-grow: 0;
  margin-right: 30px;
  margin-left: auto;
}

.global-menu__link {
  @extend .button, .button--achromic-plain;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 700;
  &:last-child {
    padding: 1rem 1.5rem;
    @include media(large-up) {
      line-height: 1.5rem;
      padding: 1rem 1.5rem;
      min-width: 2.5rem;
    }
  }

  &--active {
    @extend .button--active;
  }
  &::before {
    font-size: 1.5rem;
  }
}

.global-menu__link-icon-image {
  @extend .button, .button--achromic-plain;

  &--hover {
    @extend .button--hover;
  }

  padding: 4px 8px 4px 8px;

  & > img {
    height: 24px;
  }

  @include media(large-up) {
    padding: 8px 5px 8px 5px;

    & > img {
      height: 30px;
    }
  }
}

.global-menu__link--default::before {
  @extend %collecticon-square;
}

.global-menu__link--explore::before {
  @extend %collecticon-compass;
}

.global-menu__link--docs::before {
  @extend %collecticon-book--bookmark;
}

.global-menu__link--about::before {
  @extend %collecticon-about;
}

.global-menu__link--share::before {
  @extend %collecticon-share;
}

/* Body
   ========================================================================== */

.page__body {
}

.region-title::before {
  @extend %collecticon-globe;
}

.pop-title::before {
  @extend %collecticon-user-group;
}

/* Footer
   ========================================================================== */

.page__footer {
}

.inner-bar {
  color: white;
  padding: 0.2em;
}

// convenience selectors

.xlarge-up {
  display: none;
  @include media(xlarge-up) {
    display: inherit;
  }
}

.large-down {
  @include media(xlarge-up) {
    display: none;
  }
}
