@use "sass:math";

.inpage__policy {
  display: grid;
  grid-template-columns: 60vw auto;
  @include media(small-down) {
    grid-template-columns: 100vw;
    height: 70vh;
  }
  height: $body-height;
  max-height: 100vh;
  column-gap: $global-spacing;

  .chart {
    height: 100%;
    width: 100%;
    max-height: $body-height;
    display: grid;
    grid-template-rows: 5rem auto;
    position: relative;

    h1 {
      margin-top: $global-spacing * 2;
      margin-left: $global-spacing * 2;
      font-size: 1.5rem;
      grid-row: 1;
    }
  }
  .data {
    box-shadow: 0 0 15px rgb(0, 0, 0, 50%);
    clip-path: inset(0px 1px 0px -15px);
    z-index: 100;
    padding: $global-spacing * 2;
    @include media(medium-up) {
      max-width: 35rem;
      height: 100%;
    }
    @include media(small-down) {
      height: calc(85vh - 50px);
    }
    display: flex;
    flex-direction: column;
    .sidebar-content {
      overflow: auto;
      flex-basis: 50vh;
      flex-grow: 1;
      flex-shrink: 1;
      padding-bottom: 2rem;
    }
    h1 {
      font-weight: normal;
      font-family: "Rubik-light";
      color: $primary-color;
      text-transform: uppercase;
    }
    h2 {
      font-weight: bold;
      font-family: "Rubik";
      font-size: 1.25rem;
    }

    h3 {
      font-weight: bold;
      font-family: "Rubik";
      font-size: 1rem;
      margin-top: 1rem;
    }
    ul {
      width: 90%;
      list-style-position: inside;
      &.sources li {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      li p {
        display: inline;
      }
    }
    img {
      max-width: 100%;
    }
  }

  .button--wide {
    padding: 0.25rem 3rem;
  }

  .resetzoom,
  .backzoom,
  .section-toggle {
    @extend .button, .button--primary-raised-dark, .button--wide;
    @include media(small-down) {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    position: absolute;
    text-transform: uppercase;
    font-size: 0.875em;
    vertical-align: baseline;
    &.disabled {
      background-color: #aaa;
    }
  }
  .resetzoom {
    bottom: 20px;
    left: 20px;
    &:before {
      vertical-align: baseline;
      @extend %collecticon-arrow-loop;
    }
  }
  .backzoom {
    bottom: 20px;
    right: 20px;
    &:before {
      vertical-align: baseline;
      @extend %collecticon-arrow-up;
    }
  }
  .section-toggle {
    top: 20px;
    right: 20px;
    width: 15em;
    padding-left: 0;
    padding-right: 0;
  }

  @include media(small-down) {
    .resetzoom,
    .backzoom {
      bottom: 50px;
    }
    .section-toggle {
      top: 50px;
    }
  }
}

// Fix upward swipe to open sidebar
// When the subtitle is styled to be in the document and the drawer is closed (partially on-screen),
// the element is scrollable. This breaks swipe detection
.closed h2 {
  display: none;
}
.closed h1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.no-scroll {
  overflow-y: clip;
  @supports (max-height: 100svh) {
    max-height: 100svh;
  }
  max-height: 100vh;
}
