/* ==========================================================================
   Tables
   ========================================================================== */

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: $global-spacing;
  font-family: $heading-font-family;
  font-size: 0.875em;

  &:not(:last-child) {
    box-shadow: inset 0 (-$base-border-width * 0.5) 0 0 $base-alpha-color;
  }

  th,
  td {
    padding: $global-spacing * 0.5;
    vertical-align: top;
    box-shadow: inset 0 (-$base-border-width * 0.5) 0 0 $base-alpha-color;
  }

  th:first-child,
  td:first-child {
    padding-left: $global-spacing;
  }

  th:last-child,
  td:last-child {
    padding-right: $global-spacing;
  }

  thead th {
    @extend .heading-alt;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: tint($base-color, 56%);
    vertical-align: bottom;
    box-shadow: inset 0 (-$base-border-width) 0 0 $base-alpha-color;

    a {
      display: inline-block;

      &.table__sort::after {
        font-size: 1rem;
        margin-left: 0.375rem;
        vertical-align: top;
        display: inline-block;
        line-height: inherit !important;
        width: 1rem;
        color: tint($base-color, 28%);
      }

      &.table__sort--none::after {
        @extend %collecticon-sort-none;
      }

      &.table__sort--asc::after {
        @extend %collecticon-sort-asc;
      }

      &.table__sort--desc::after {
        @extend %collecticon-sort-desc;
      }
    }

    a,
    a:visited,
    a:hover {
      color: inherit;
    }
  }

  tbody th {
    font-weight: $heading-font-bold;
  }

  tbody tr:last-child td,
  tbody tr:last-child th {
    box-shadow: none;
  }

  tfoot td {
    box-shadow: inset 0 $base-border-width 0 0 $base-alpha-color;
  }

  &--zebra {
    tbody tr:nth-of-type(odd) {
      background: rgba($base-color, 0.04);
    }
  }

  .table__empty-cell {
    text-align: center;
    font-weight: $base-font-regular;
    color: rgba($base-font-color, 0.48);
    font-style: italic;
  }
}

/* Prose specific */

.prose--responsive {
  > .table thead th {
    @include media(medium-up) {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
}

/* Responsive table wrapper
   ========================================================================== */

.responsive-table-wrapper {
  position: relative;
  min-height: 0.01%;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  margin-bottom: $global-spacing;

  > *:last-child {
    margin-bottom: 0;
  }
}
