/* ==========================================================================
   Explore: single
   ========================================================================== */

@use "sass:math";

.inpage--map.inpage--single {
  .inpage__header {
    overflow: hidden; /* Fixed for ShadowScrollbar */
  }

  .inpage__subheader {
    box-shadow: none;
  }

  .inpage__title {
    line-height: 1.5rem;
  }

  .inpage__title,
  .inpage__subtitle {
    @extend .truncated;
    max-width: 100%;
  }

  .inpage__center {
    padding-right: 4px;
    max-height: 80vh;
  }

  .inpage__body {
    padding: 0;
    display: grid;

    @include media(small-down) {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    min-height: calc(100vh - 3em);
    max-height: calc(100vh - 3em);
  }
}

.inpage__results {
  margin: 0 0.25rem;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  @include media(medium) {
    //        min-height: calc(100vh - 56px);
    max-height: calc(100vh - 56px);
  }
  @include media(large-up) {
    //        min-height: 100vh;
    max-height: 100vh;
    margin-left: 1rem;
  }
}

.inpage__results_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.25rem;
}

.inpage__results_chart {
  margin-top: 1rem;
  flex: auto;
}

.inpage__results_pie {
  min-width: 100px;
  min-height: 100px;
  max-width: 200px;
  flex-basis: 50%;
  @include media(medium-up) {
    flex-basis: 133px;
  }
}

figure.results__cost_pie {
  margin: 0;
  text-align: center;
}

.inpage--map.inpage--single.inpage--rainfall {
  .inpage__body {
    grid-template-rows: 90% 10%;
    @include media(small-down) {
      min-height: 100%;
    }
  }
}

.primary-color {
  color: $primary-color !important;
  fill: $primary-color !important;
  stroke: $primary-color !important;
}

.darker-primary-color {
  color: $darker-primary-color !important;
  fill: $darker-primary-color !important;
  stroke: $darker-primary-color !important;
}

.danger-color {
  color: $danger-color !important;
}

.base-color {
  color: $base-color !important;
}

/* Controls
   ========================================================================== */

.econtrols {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden; /* Fixed for ShadowScrollbar */
  height: 100%;
  padding-bottom: 0.5em;

  .nav {
    margin: 0;
    position: relative;
    z-index: 80;
  }

  .nav__tablist {
    padding: 0 $contained-spacing;
  }
}

.econtrols__section {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden; /* Fixed for ShadowScrollbar */

  /* Animation */
  animation: reveal 0.32s ease 0s 1;
}

.econtrols__title {
  @extend .visually-hidden;
}

.econtrols__block {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
  overflow: hidden; /* Fixed for ShadowScrollbar */
}

.econtrols__subblock {
  position: relative;
  z-index: 60;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
  overflow: hidden; /* Fixed for ShadowScrollbar */
  height: 300px;
}

.econtrols__item {
  padding: $global-spacing * 0.5 $contained-spacing * 0.5;
  //box-shadow: inset 0 (-$base-border-width) 0 0 $base-alpha-color;
  margin: 0;

  &:last-child {
    box-shadow: none;
  }

  .form__label,
  .form__option__text,
  .form__output {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .form__option__ui {
    margin: 0;
  }

  .form__option--switch {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    margin: 0;

    .form__option__text {
      order: -1;
      font-weight: $base-font-bold;
      padding-right: $global-spacing;
      margin: 0;
    }

    .form__option__ui {
      margin: 0.25rem 0 0.25rem auto;
    }
  }

  .input-range__label--value {
    @extend .visually-hidden;
  }

  .form__inner-actions {
    margin-top: -0.125rem;

    > *:last-child {
      margin-right: -0.375rem;
    }
  }

  .form__slider-group {
    margin-top: -0.75rem;
  }
}

.econtrols__actions {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 80;
  padding: $contained-spacing;
  max-width: 100vw;
}

.econtrols__submit {
  @extend .button, .button--primary-raised-dark;

  &::before {
    @extend %collecticon-sm-tick;
  }
}

.econtrols__download-group {
  display: flex;
  flex-direction: column;
  align-content: center;

  div {
    width: 100%;
    flex: 1;
    text-align: center;
  }
}

.econtrols__download {
  @extend .button, .button--primary-raised-dark;

  &::before {
    @extend %collecticon-download-2;
  }
}

.econtrols__upload {
  @extend .button, .button--primary-raised-dark;

  &::before {
    @extend %collecticon-upload-2;
  }
}

.econtrols__map {
  @extend .button, .button--primary-raised-dark;

  &::before {
    @extend %collecticon-marker;
  }
}

.econtrols__link {
  font-size: 0.75rem;

  &::before {
    padding-right: 0.25rem;
  }
}

.econtrols__time-select {
  @extend .button, .button--primary-raised-light;
  min-width: 5rem;

  &::after {
    @extend %collecticon-sm-chevron-down;
    margin-right: -0.5rem;
  }
}

.econtrols__reset {
  @extend .button, .button--primary-raised-light;

  &::before {
    @extend %collecticon-arrow-loop;
  }
}

/* 'eci' stands for econtrol item */
.eci-info {
  @extend .button, .button--small, .button--base-plain, .button--text-hidden;

  &::before {
    @extend %collecticon-circle-information;
    opacity: 0.56;
  }
}

/* Map
   ========================================================================== */

.exp-map {
  flex-grow: 2;
  position: relative;
  z-index: 10;
  background: tint($base-color, 96%);
  display: flex;
  justify-content: center;
  align-items: stretch;
  align-self: stretch;
  overflow: hidden; /* Fixed for ShadowScrollbar */
  height: 100%;

  /* Animation */
  animation: reveal 0.64s ease 0s 1;

  .mapboxgl-ctrl-group {
    margin-bottom: 0;
  }

  > div {
    flex-grow: 2;
    min-height: 100% !important;
    height: inherit !important; /* ios safari bug fix */
  }

  .map-controls__position {
    position: absolute;
    bottom: 30px;
    left: 20px;
  }
}

.exp-map__title {
  position: absolute;
  top: $global-spacing;
  left: $global-spacing;
  text-shadow: 0 0 1px white;
  padding: 0 math.div($global-spacing, 3);
  border-radius: math.div($global-spacing, 3);
  background-color: #ffffffdd;

  h2 {
    margin-bottom: 0;
  }
}

.exp-map__logos {
  height: 3rem;
  padding-top: 0.5rem;

  img {
    max-height: 2rem;
    margin-right: 1rem;
  }

  img.logo-gwsp {
    max-height: 3rem;
    margin-top: -0.5rem;
  }
}

.map-number-list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  > * {
    grid-column: auto / span 12;
    margin: 0;
  }

  dt {
    font-size: 0.75rem;
    margin-bottom: $global-spacing;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  dd {
    font-size: 1rem;
    font-weight: $base-font-bold;

    @for $child from 1 through 6 {
      &:nth-of-type(#{$child}) {
        grid-row: 2 * $child - 1;
      }
    }
  }

  small {
    font-size: inherit;
  }
}

.mapboxgl-ctrl > button {
  @extend .button, .button--primary-raised-light, .button--text-hidden;
}

.layers-menu-trigger::before {
  @extend %collecticon-iso-stack;
}

.layers-menu {
  width: 20rem;
  height: 15.5rem;
  padding: 0;

  .drop__title {
    margin-bottom: $global-spacing * 1.5;
  }

  .rcs {
    > div > div {
      padding: $global-spacing;
    }
  }
}

.layers-list {
  list-style: none;
  margin: 0 (-$global-spacing);
  padding-right: $global-spacing;

  &__item {
    padding: $global-spacing;
    box-shadow: 0 $base-border-width 0 0 $base-alpha-color;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      box-shadow: none;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  .form__group {
    margin-bottom: $global-spacing * 0.5;
  }

  .form__option--switch {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    margin: 0;

    .form__option__text {
      order: -1;
      font-weight: $base-font-bold;
      line-height: 1.25rem;
      padding-right: $global-spacing;
      margin: 0;
    }

    .form__option__ui {
      margin: 0.25rem 0 0.25rem auto;
    }
  }

  .form__help {
    position: relative;
    padding-top: $global-spacing * 0.5;
    text-align: left;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 2rem;
      height: 1px;
      content: "";
      background: $base-alpha-color;
    }
  }
}

/* Summary
   ========================================================================== */

.exp-summary {
  @extend %pane-skin;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100vh;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;

  /* Animation */
  animation: reveal 0.96s ease 0s 1;
}

.exp-summary__header {
  position: relative;
  z-index: 80;
  display: flex;
  flex-flow: row nowrap;
  padding: $global-spacing $contained-spacing;
  box-shadow: 0 $base-border-width 0 0 $base-alpha-color;

  > *:last-child {
    margin-bottom: 0;
  }
}

.exp-summary__headline {
  > *:last-child {
    margin-bottom: 0;
  }
}

.exp-summary__title {
  @include heading(1rem);
  margin-bottom: 0;
}

.exp-summary__subtitle {
  @extend .heading-alt;
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgba($base-font-color, 0.64);
  margin: 0 0 $global-spacing 0;
}

.exp-summary__disclaimer {
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgba($base-font-color, 0.64);
  margin: 0;

  > *:last-child {
    margin-bottom: 0;
  }
}

.exp-summary__body {
  position: relative;
  z-index: 60;
  flex: 1;
  overflow: hidden; /* Fixed for ShadowScrollbar */
}

.exp-summary__footer {
  position: relative;
  z-index: 80;
  padding: $contained-spacing;
  box-shadow: 0 (-$base-border-width) 0 0 $base-alpha-color;
  text-align: center;
}

.exp-download-button {
  @extend .button, .button--primary-raised-dark;
  min-width: 10rem;
  vertical-align: top;

  &::before {
    @extend %collecticon-download-2;
  }
}

.sum-block {
  padding: $global-spacing $contained-spacing;
  box-shadow: 0 $base-border-width 0 0 $base-alpha-color;

  &:last-child {
    box-shadow: none;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.sum-block--message {
  height: 100%;
  text-align: center;
  color: rgba($base-font-color, 0.64);
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.sum-block--error h2 {
  color: $danger-color;
}

.sum-block--charts {
  padding: ($global-spacing * 2) $contained-spacing;
}

.sum-block__title {
  @extend .visually-hidden;
}

.sum-number-list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  > * {
    grid-column: auto / span 12;
    margin: 0;
  }

  dt {
    font-size: 0.75rem;
    margin-bottom: $global-spacing;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  dd {
    font-size: 1.25rem;
    font-weight: $base-font-bold;

    @for $child from 1 through 6 {
      &:nth-of-type(#{$child}) {
        grid-row: 2 * $child - 1;
      }
    }
  }

  small {
    font-size: inherit;
  }
}

.sum-chart-media,
.profile-chart-media {
  text-align: center;
  margin-bottom: 0;

  /* Animation */
  /*animation: pop-in 0.16s ease 0s 1;*/
}

.sum-area-chart-media__item,
.profile-chart-media__item,
.inaction__chart_item,
.transitioncost__chart_item {
  margin-bottom: 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1rem;
    margin-bottom: 0;
    max-width: 75vw;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  text {
    fill: $base-color;
    opacity: 0.8;
  }

  svg {
    vertical-align: top;
    cursor: pointer;

    /* Animation */
    transition: transform 0.16s ease 0s;

    .vx-axis-left {
      stroke-opacity: 0.5;

      text {
        font-size: 0.7em;
      }
    }

    .vx-axis-bottom {
      stroke-opacity: 0.5;

      text {
        font-size: 0.9em;
      }
    }
  }

  .chart__yaxis_label {
    font-size: 0.8rem;
    font-weight: $heading-font-bold;
  }

  .chart__legend {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: wrap;
    font-size: 0.8rem;
    padding-right: 35px; /* match left margin in svg */
    @include media(large-up) {
      flex-wrap: nowrap;
    }
  }

  .chart__legend_item {
    padding-left: 1.5rem;
  }

  .chart__legend_label {
    padding-left: 0.25rem;
  }
}

.value--sub {
  font-size: 0.75rem;
}

.sum-chart-media__caption {
  @extend .heading-alt;
  color: rgba($base-font-color, 0.64);
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: -1.3rem;
}

.profile-chart-media__caption,
.inaction__chart-media__caption,
.transitioncost__chart-media__caption,
.disaggregatedcost__chart-media__caption {
  @extend .heading-alt;
  color: $base-font-color;
  font-size: 0.75rem;
  line-height: 1rem;
  font-family: Rubik, sans-serif;
}

.chart-number-list {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0 ($global-spacing * 0.5);

  > * {
    margin: 0;
  }

  dt {
    display: flex;
    align-items: center;
    grid-column: 1;
  }

  dd {
    display: flex;
    flex-flow: row nowrap;
    font-size: 1rem;
    font-weight: $base-font-bold;
    grid-column: 2;

    span {
      margin-right: 1rem;
      text-align: right;
      min-width: 4rem;
    }

    small {
      font-size: 0.75rem;
      font-weight: $base-font-regular;
      margin-left: auto;
    }
  }
}

.drop__menu-item--pdf::before {
  @extend %collecticon-page-label;
}

.drop__menu-item--shapefile::before {
  @extend %collecticon-expand-top-right;
}

.drop__menu-item--data::before {
  @extend %collecticon-expand-top-right;
}

/* popup items */

.detail__location {
  padding-top: 0.375rem;
  padding-left: 0;
  text-align: left;
  font-size: 0.75em;
}

.detail__explanation {
  max-width: 600px;
  height: 200px;
  padding: $contained-spacing;
}

.ios .overlay__detail {
  height: inherit;
}

.overlay__detail {
  background: white;
  position: absolute;
  top: 4rem;
  max-width: 100vw;
  height: min-content;

  .econtrols {
    box-shadow: 0 -1px 1px 0 rgba($base-color, 0.24),
      0 2px 6px 0 rgba($base-color, 0.24);
  }

  z-index: 100;
  /* not getting the events otherwise, tab is capturing */
  .mapboxgl-popup-close-button {
    z-index: 1000;
  }
}

.layers-links {
  li {
    list-style-type: none;
    padding: 4px;
    margin-left: 4px;
    margin-right: 4px;
  }
}

.layers__legend-description {
  font-size: 0.875em;
  color: $nav-inactive-color;
}

.layers__legend-range {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.7rem;
  padding: 0 ($global-spacing * 0.25);
}

.layers__legend {
  color: $nav-inactive-color;
}

.layers__legend-units {
  font-size: 0.7rem;
  padding: 0 ($global-spacing * 0.25);
}

.layers__legend-source {
  font-size: 0.7rem;
  padding: ($global-spacing * 0.25) 0;
}

.layers__legend-img {
  width: 100%;
  height: 1rem;
  background-size: cover;
  background-position: center;
  background-repeat: "no-repeat";
  margin: ($global-spacing * 0.5) 0;
}

.layers__legend-blob {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.625rem;
  margin-right: ($global-spacing * 0.5);
  float: left;
}

.layers__container {
  position: fixed;
  background: white;
  bottom: 40px;
  left: 0px;
  width: 25rem;
  max-width: 75vw;
  height: 50vh;
  overflow: hidden;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.layer_selector__container {
  display: flex;
  flex-direction: row;

  button {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    margin: 1px;
  }

  button.selected {
    background: #eee;
  }
}

.layer_selector__btn_container {
  position: relative;
}

.layer_selector__list {
  position: absolute;
  left: 10px;
  bottom: 31px;
  background: white;
  width: 12rem;
  z-index: 4;

  .layers-list {
    margin: 0;
    padding-right: 0;
  }

  .layers-list__item,
  .layers-list__item:first-child {
    padding: 0.25rem;
  }

  .layers-list__item {
    a {
      display: block;
      width: 100%;
    }

    a:hover {
      background: #e6e6e6;
    }
  }
}

// Rise

.rise__panel {
  width: 100%;
  font-size: 0.75em;

  .rise__score_pie__item {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }

  .risetextresults__row {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: flex-end;

    .risetextresults__container {
      flex-grow: 1;
      flex-basis: 25%;
      padding: 0 ($global-spacing * 0.25);
    }
  }
}

.risetextresults__title {
  padding: 4px;
}

.rise-no-pad > .grid-container__risetext > .risetextresults__title {
  padding-top: 0;
}

.rise-big-text {
  margin-top: 20%;
}

// Inaction chart

.inaction__chart_item,
.transition__chart_item {
  position: relative;
}

.inpage__row {
  display: flex;
  flex-direction: row;
  height: calc(46vh - 2rem);
}

.inpage__row_country {
  background-color: #fff;
  height: auto;
}

.inpage__result_panel {
  flex-grow: 1;
  flex-basis: 33%;
  height: inherit;
  padding: 0.5rem;
  //overflow: hidden;
  background-color: #fff;
}

figcaption {
  margin-bottom: 0.5rem;
  //font-size: 1rem !important;
  text-align: left;
  text-transform: none !important;
}

.vx-legend-label {
  font-size: 12px;
}

.grid-container__rise {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding: 20px;
}

.grid-container__risetext {
  display: grid;
  grid-template-columns: 1fr 6fr;
}

.rise__panel,
.risetextresults__row {
  font-size: 12px;
}

.risetextresults__value {
  min-width: 30px;
  text-align: center;
  background-color: rgb(12, 35, 63);
}

.region__title {
  font-size: 14px;
  color: grey;
}

.country__title {
  font-size: 24px;
}

.pop-title::before {
  font-size: 1.5rem;
}

.p1 {
  padding: 1rem;
}

.results__cost_pie__item {
  width: 100%;
}

.transitioncost__chart {
  .visx-axis-tick {
    tspan {
      display: none;
    }
  }
}

.visx-legend-label {
  font-size: 12px;
}

.visx-axis-tick {
  text {
    font-size: 12px;
  }
}

.tooltip-wrapper {
  padding: 6px;
  max-width: 175px;
  .tooltip__value {
    margin-top: 8px;
  }
}

.primary-text-color {
  color: $base-color !important;
}

.card__header_pop {
  display: block;
}

.pop-num {
  font-family: "Rubik-light", sans-serif !important;
  float: right;
}

.card--region {
  height: 8rem;
}

.yaxis__text {
  font-weight: bold;
  font-size: 0.75rem;
}

.label__population {
  font-size: 0.66rem;
  pointer-events: none;
}

.disaggregated__label {
  font-size: 0.75rem;
  pointer-events: none;
}

.disaggregated__rotate:after {
  @extend %collecticon-arrow-spin-clockwise;
  padding-left: 0.25rem;
}

.filter__context_label {
  font-size: 0.875rem;
}

.annotation__bar-label {
  font-size: 0.75rem;
  font-family: "Rubik";
  pointer-events: none;
}

.inpage--padding {
  padding-top: 30px;
  background: #fff;
}

.ctab--download {
  font-size: 0.8em;
  float: right;
  margin: 0;
}

.improved--header {
  display: flex;
  justify-content: space-between;
}

.body__header {
  text-align: center;
}

.filter__flex {
  display: flex;
}

.filter__ul {
  list-style: none;
}

.ractive {
  font-size: 2em;
}

.decarbonization__charts {
  padding: 0.5em;
}

.chart-media__caption {
  font-size: 1.25rem;
  margin-bottom: 0;
}

.big-num {
  font-size: 2em;
  color: $primary-color;
  font-weight: 700;
  margin: 0;
}

.byosc-builder-collapse {
  box-shadow: 0 0 15px rgb(0, 0, 0, 75%);
  clip-path: inset(0px -15px 0px 0px);
  z-index: 100;
  height: $body-height; //page header
}

.mapboxgl-ctrl-group > button::before {
  font-size: 1.5rem !important;
}

.homepage-description {
  font-size: 1.9rem;
  line-height: 2.5rem;
  @include media(small-down) {
    width: 95vw;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.scroll-to-section {
  height: 100vh !important;
  //height: 100vh;
  display: flex;
  align-items: center;
  font-size: 1.9rem;
  line-height: 2.5rem;
  position: relative;
}

.scroll-to-sect-btn {
  display: block;
  position: absolute;
  bottom: 5rem;
  right: 0;
  @include media(small-down) {
    z-index: 1000;
    display: none;
    &.visible {
      display: block;
      position: fixed;
      bottom: 1rem;
    }
  }
}

.scroll-to-sect-btn-home {
  display: block;
  position: absolute;
  bottom: 8rem;
  right: 0;
  text-decoration: none;
  @include media(small-down) {
    z-index: 1000;
    display: none;
    &.visible {
      display: block;
      position: fixed;
      bottom: 1rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .scroll-to-sect-btn-home {
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

  .scroll-to-sect-btn {
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
}

a.link-with-line {
  text-decoration: underline;
}

a.scroll-to-sect-btn-home {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
a.scroll-to-sect-btn-home {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

a.scroll-to-sect-btn {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
a.scroll-to-sect-btn {
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
