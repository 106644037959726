.side-nav {
  margin: 1rem;

  .side-nav__link--active {
    font-weight: 500;
  }

  li {
    list-style-type: none;
    padding: 1rem;
  }
}

.sticky-nav {
  position: sticky;
  top: 0px;
}
