.inpage--countries {
  @include media(large-up) {
    grid-template-columns: 15rem calc(100vw - 30rem) 15rem;

    .inpage__header {
      grid-column-end: span 3;
    }
    .inpage__body {
      grid-column: 2;
    }
  }
  .inpage__headline {
    width: 100%;
  }
}

.countries-title {
  width: 100%;
}

.countries-detail-flag {
  width: 2.5em;
  display: inline-block;
  border: 1px solid #eee;
  margin-left: auto;
}

.country-select-page {
  padding-top: 3rem;
  padding: 1rem;
  width: 100%;
  justify-items: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.country-select {
  list-style: none;
  display: grid;
  @include media(medium-up) {
    grid-template-columns: repeat(5, 20rem);
  }
  @include media(small-down) {
    grid-template-columns: repeat(2, 12rem);
  }
  grid-auto-rows: 5rem;
  align-items: center;
}

.country-select-country a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 1em;
  transition: background-color 0.25s;
  &:hover {
    background-color: rgba(0, 0, 0, 33%);
    opacity: 1;
  }
}

.country-select-country-label {
  padding-left: 0.5em;
}

.country-select-flag {
  height: 30px;
  display: inline-block;
  border: 1px solid #eee;
}

.countries-data-resource {
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.countries-data-resource-header {
  color: $primary-color;
  width: 100%;
  text-transform: uppercase;
  border-bottom: 2px $primary-color solid;
}

.countries-data-global-context {
  // height: 30vh;
}

.countries-data-global-context-bars {
  margin-left: 15px;
  p {
    margin-bottom: 0;
  }
}

.countries-title {
  display: flex;
  flex-direction: row;
  align-items: start;
}

.country-policy-inner {
  margin-left: 15px;
}

.country-policy-list {
  list-style: none;
}

.country-policy-sources-header {
  margin-bottom: 0px;
  font-family: $heading-font-family;
}

.country-policy-sources {
  margin-bottom: 15px;
  list-style-position: inside;
  li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 70vw;
  }
}

.country-policy-policy {
  font-family: $base-font-family;
}

.country-policy-header-1 {
  color: $primary-color;
  width: 100%;
  text-transform: uppercase;
  border-bottom: 2px $primary-color solid;
}

.country-policy-header-2 {
  text-transform: uppercase;
  margin-bottom: 5px;
}

.country-policy-header-3 {
  color: $primary-color;
  font-size: 1.25rem;
  margin-bottom: 0px;
}

.country_details_header {
  margin-top: 2rem;
}
