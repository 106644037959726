/* ==========================================================================
   Skins
   ========================================================================== */

%card-skin {
  border-radius: $base-border-radius;
  background: #fff;
  box-shadow: 0 0 32px 2px rgba($base-color, 0.04),
    0 16px 48px -16px rgba($base-color, 0.12);
}

%card-skin--active {
  box-shadow: 0 0 32px 4px rgba($base-color, 0.04),
    0 16px 48px -8px rgba($base-color, 0.12);
}

%pane-skin {
  background: #fff;
  box-shadow: 0 0 16px 4px rgba($base-color, 0.08);
}

%drop-skin {
  border-radius: $base-border-radius;
  background: #fff;
  box-shadow: 0 0 32px 2px rgba($base-color, 0.08),
    0 16px 48px -16px rgba($base-color, 0.16);
}

%popover-skin {
  border-radius: $base-border-radius;
  background: #fff;
  box-shadow: 0 0 32px 2px rgba($base-color, 0.08),
    0 16px 48px -16px rgba($base-color, 0.16);
}

%inset-skin {
  background: tint($base-color, 96%);
}

%control-skin {
  border-radius: $base-border-radius;
  background: #fff;
  box-shadow: 0 0 16px 4px rgba($base-color, 0.08);
}
