/* ==========================================================================
   Explore: hub
   ========================================================================== */

.inpage--map.inpage--hub {
  .inpage__hactions {
    margin-left: 0;
  }
}

/* Country list
   ========================================================================== */

.card--country {
  .card__header {
    padding: $global-spacing;
  }

  .card__title {
    @extend .truncated;
  }

  .card__contents {
    flex-flow: row nowrap;
    align-items: center;
  }

  .card__media {
    margin-right: 0;

    img {
      width: 4rem;
      height: 3rem;
    }
  }

  .card__thumb {
    min-width: 4rem;
  }
}

/* Model list
   ========================================================================== */

.card--model {
  .card__title {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;

    > span {
      padding-right: $global-spacing;
    }

    .label {
      margin-left: auto;
    }
  }
}
