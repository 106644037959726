.outer-bar,
.inner-bar {
  height: 20px;
}

.outer-bar {
  background: $home-light-grey-color;
  .value {
    color: $primary-color;
    padding-left: 0.25rem;
    display: inline-block;
    position: absolute;
    height: 100%;
    top: 0.25rem;
  }
}

.inner-bar {
  background: $primary-color;
  display: inline-block;
  overflow-x: show;
  .value {
    color: white;
  }
}

.singlebar__container {
  display: block;
  width: 100%;
}
