.headline_metrics,
.chain__headline-metrics {
  text-align: center;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.metricsbar-title {
  font-family: Rubik, "Rubik-light", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}
