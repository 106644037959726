// Windows can display the icon fonts smushed up against the top of the bounding box
// This moves them down so that they're approximately inline
// Reinvestigate this when updating the font generation routines.

.win %collecticon,
.win [class^="collecticon-"],
.win [class*=" collecticon-"] {
  transform: translateY(30%);
}

//The link which when clicked opens the collapsable area
.win .Collapsible__trigger {
  &.is-open {
    &:after {
      transform: translateY(-15%) rotateZ(180deg);
    }
  }
}
