.inpage--left-panel {
  @include media(medium-up) {
    grid-template-columns: auto 1fr;
  }
}

.inpage--right-panel {
  @include media(medium-up) {
    grid-template-columns: 1fr auto;
  }
}

.inpage--right-panel,
.inpage--left-panel {
  @include media(medium-up) {
    .inpage__body {
      grid-template-rows: 0fr auto 0fr;
    }

    .body__header,
    .body__footer {
      height: min-content;
    }
  }
}

.bbold {
  font-family: "Rubik";
  font-weight: bold;
}
