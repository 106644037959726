@font-face {
  font-family: Rubik;
  src: url(../../assets/fonts/Rubik-Medium.ttf);
}

@font-face {
  font-family: "Rubik-light";
  src: url(../../assets/fonts/Rubik-Light.ttf);
}

/* ==========================================================================
   Variables
   ========================================================================== */

/* Colors
   ========================================================================== */

$base-color: #2e344b; // Dark Slate Grey
$primary-color: #028e58; // Turquoise
$primary-highlight-color: #7fe5db;
$darker-primary-color: #002f53;
$secondary-color: #fde051;
$background-color: white;
$home-background-color: #1d2c4b;

$home-rainfall-color: #fde051;
$home-calorie-color: #0c233f;

$nav-inactive-color: #999;
$home-light-grey-color: #d9dbe0;

/* State */

$danger-color: #d85d3f; // Outrageous Orange
$success-color: #216869; // Myrtle Green
$warning-color: $secondary-color;
$info-color: $primary-color;

/* Helpers */

$link-color: $primary-color;
$base-alpha-color: rgba($base-color, 0.08);

/* Infographics */

$alpha-color: #fe5931;
$beta-color: #ffc700;
$gama-color: #1ea896;
$delta-color: #19647e;

/* Typography
   ========================================================================== */

$root-font-size: 12px;

$base-font-color: tint($base-color, 16%);
$base-font-family: "Rubik, Rubik-light", sans-serif;
$base-font-style: normal;
$base-font-light: 300;
$base-font-regular: 400;
$base-font-medium: 400;
$base-font-bold: 600;
$base-font-weight: $base-font-light;
$base-font-size: 1rem;
$base-line-height: 1.5;

$code-font-family: Menlo, Monaco, Consolas, "Courier New", monospace;

$heading-font-family: Rubik, $base-font-family;
$heading-font-light: 300;
$heading-font-regular: 400;
$heading-font-medium: 400;
$heading-font-bold: 500; // Medium (500) being used as bold (700)
$heading-font-weight: $heading-font-medium;

/* Decoration
   ========================================================================== */

/* Border radius */

$base-border-radius: 0.25rem;
$full-border-radius: 320rem;

$base-border-width: 1px;

/* Sizing, spacing and media queries
   ========================================================================== */

/* Spacing */

$global-spacing: 1rem;
$contained-spacing: 1.5rem;

/* Rows */

$row-min-width: 960px;
$row-max-width: 1280px;
$jeet-max-width: $row-max-width; // reset jeet.gs max width

/* Media queries */

$xsmall-range: (0, 543px);
$small-range: (544px, 767px);
$medium-range: (768px, 1023px);
$large-range: (1024px, 1399px);
$xlarge-range: (1400px);

$screen: "only screen";

/* slider marker */

$input-range-slider-height: 0.85rem;
$input-range-slider-width: 0.85rem;

/* input range track */
$input-range-track-height: 0.25rem;

$body-height: calc(100vh - 36px);
