/* ==========================================================================
   Explore: global
   ========================================================================== */

.inpage__wrapper {
  padding: 10px;
  //border:1px solid $primary-color;
  background-color: white;
}

.inpage--map {
  .inpage__header {
    @include media(large-down) {
      display: none;
    }
  }
  .inpage__header.show {
    @include media(large-down) {
      display: block;
      position: fixed;
      width: 25rem;
      height: 100%;
      max-height: calc(100vh - 56px);
      z-index: 1000;

      animation: slide-in-out-left 0.64s ease-in-out 0s 1;
    }
    @include media(xsmall-only) {
      width: 100%;
      position: relative;
      z-index: inherit;
      height: calc(100vh - 56px);
    }
  }
}

.ios .inpage__header.show {
  /* don't hide the apply behind the footer */
  /* Should be 100px, I htink there are additional bars somewhere */
  @include media(xsmall-only) {
    height: calc(100vh - 120px);
  }
}

.exp-change-button {
  @extend .button, .button--small, .button--primary-plain, .button--text-hidden;

  /* Animation */
  animation: slide-in-right 0.64s ease-in-out 0s 1;

  &::before {
    @extend %collecticon-arrow-left-right;
  }
}

#infotip-global {
  padding: 12px !important;
  font-size: 0.875em !important;
  color: #33465e;
  max-width: 20em;
  text-align: left;
  opacity: 0.9 !important;
}

.__react_component_tooltip {
  text-align: left;
}
