/* ==========================================================================
   Global: Loading
   ========================================================================== */

.loading-pane {
  @extend .antialiased;
  background: radial-gradient(farthest-side, #fff, transparent);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9997;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
}

.loading-message {
  margin: 1rem 0 0 0;
}

/* Worm
    ========================================================================== */

.loading-worm {
  position: relative;
  height: 0.75rem;
  border-radius: $full-border-radius;
  background: rgba($base-color, 0.24);
  width: 16rem;
  overflow: hidden;

  &::before {
    position: absolute;
    content: "";
    height: 100%;
    background: $primary-color;
    border-radius: $full-border-radius;

    /* Animation */
    animation: worm-run 1.64s infinite ease-in-out;
  }
}

@keyframes worm-run {
  0% {
    left: 0;
    width: 0;
  }

  50% {
    width: 100%;
    right: 0;
  }

  100% {
    left: 100%;
    width: 0;
  }
}

/* Loading pane react animation
    ========================================================================== */

.loading-pane-enter {
  transform: translate3d(0, 0, 0);
  transition: opacity 0.32s ease 0s, visibility 0.32s linear 0s;
  opacity: 0;
  visibility: hidden;

  &.loading-pane-enter-active {
    opacity: 1;
    visibility: visible;
  }
}

.loading-pane-exit {
  transition: opacity 0.32s ease 0s, visibility 0.32s linear 0s;
  opacity: 1;
  visibility: visible;

  &.loading-pane-exit-active {
    opacity: 0;
    visibility: hidden;
  }
}
