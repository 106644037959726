/* ==========================================================================
   Icon font: Collecticons
   ========================================================================== */

/**
 * Designed by Development
 * https://developmentseed.org/
 *
 * Generated with collecticons-processor on November 9th, 2023
 * http://collecticons.io/
 */

@font-face {
  font-family: "Collecticons";
  src: url('../fonts/Collecticons.woff');
  src: url('../fonts/Collecticons.woff2');
  font-weight: normal;
  font-style: normal;
}

%collecticon,
[class^="collecticon-"],
[class*=" collecticon-"] {
  speak: none;
  font-family: "Collecticons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better font rendering */
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%collecticon-about {
  @extend %collecticon;
  content: "\F101";
}

.collecticon-about:before {
  content: "\F101";
}

%collecticon-arrow-down {
  @extend %collecticon;
  content: "\F102";
}

.collecticon-arrow-down:before {
  content: "\F102";
}

%collecticon-arrow-left-right {
  @extend %collecticon;
  content: "\F103";
}

.collecticon-arrow-left-right:before {
  content: "\F103";
}

%collecticon-arrow-left {
  @extend %collecticon;
  content: "\F104";
}

.collecticon-arrow-left:before {
  content: "\F104";
}

%collecticon-arrow-loop {
  @extend %collecticon;
  content: "\F105";
}

.collecticon-arrow-loop:before {
  content: "\F105";
}

%collecticon-arrow-right {
  @extend %collecticon;
  content: "\F106";
}

.collecticon-arrow-right:before {
  content: "\F106";
}

%collecticon-arrow-spin-clockwise {
  @extend %collecticon;
  content: "\F107";
}

.collecticon-arrow-spin-clockwise:before {
  content: "\F107";
}

%collecticon-arrow-up {
  @extend %collecticon;
  content: "\F108";
}

.collecticon-arrow-up:before {
  content: "\F108";
}

%collecticon-book--bookmark {
  @extend %collecticon;
  content: "\F109";
}

.collecticon-book--bookmark:before {
  content: "\F109";
}

%collecticon-book {
  @extend %collecticon;
  content: "\F10A";
}

.collecticon-book:before {
  content: "\F10A";
}

%collecticon-brand-devseed {
  @extend %collecticon;
  content: "\F10B";
}

.collecticon-brand-devseed:before {
  content: "\F10B";
}

%collecticon-brand-facebook {
  @extend %collecticon;
  content: "\F10C";
}

.collecticon-brand-facebook:before {
  content: "\F10C";
}

%collecticon-brand-github {
  @extend %collecticon;
  content: "\F10D";
}

.collecticon-brand-github:before {
  content: "\F10D";
}

%collecticon-brand-twitter {
  @extend %collecticon;
  content: "\F10E";
}

.collecticon-brand-twitter:before {
  content: "\F10E";
}

%collecticon-calorie-loss {
  @extend %collecticon;
  content: "\F10F";
}

.collecticon-calorie-loss:before {
  content: "\F10F";
}

%collecticon-chevron-down {
  @extend %collecticon;
  content: "\F110";
}

.collecticon-chevron-down:before {
  content: "\F110";
}

%collecticon-chevron-left {
  @extend %collecticon;
  content: "\F111";
}

.collecticon-chevron-left:before {
  content: "\F111";
}

%collecticon-chevron-right {
  @extend %collecticon;
  content: "\F112";
}

.collecticon-chevron-right:before {
  content: "\F112";
}

%collecticon-chevron-step-backward {
  @extend %collecticon;
  content: "\F113";
}

.collecticon-chevron-step-backward:before {
  content: "\F113";
}

%collecticon-chevron-step-forward {
  @extend %collecticon;
  content: "\F114";
}

.collecticon-chevron-step-forward:before {
  content: "\F114";
}

%collecticon-chevron-up {
  @extend %collecticon;
  content: "\F115";
}

.collecticon-chevron-up:before {
  content: "\F115";
}

%collecticon-circle-exclamation {
  @extend %collecticon;
  content: "\F116";
}

.collecticon-circle-exclamation:before {
  content: "\F116";
}

%collecticon-circle-information {
  @extend %collecticon;
  content: "\F117";
}

.collecticon-circle-information:before {
  content: "\F117";
}

%collecticon-circle-question {
  @extend %collecticon;
  content: "\F118";
}

.collecticon-circle-question:before {
  content: "\F118";
}

%collecticon-circle-tick {
  @extend %collecticon;
  content: "\F119";
}

.collecticon-circle-tick:before {
  content: "\F119";
}

%collecticon-circle-xmark {
  @extend %collecticon;
  content: "\F11A";
}

.collecticon-circle-xmark:before {
  content: "\F11A";
}

%collecticon-clipboard-list {
  @extend %collecticon;
  content: "\F11B";
}

.collecticon-clipboard-list:before {
  content: "\F11B";
}

%collecticon-compass {
  @extend %collecticon;
  content: "\F11C";
}

.collecticon-compass:before {
  content: "\F11C";
}

%collecticon-download-2 {
  @extend %collecticon;
  content: "\F11D";
}

.collecticon-download-2:before {
  content: "\F11D";
}

%collecticon-ellipsis-vertical {
  @extend %collecticon;
  content: "\F11E";
}

.collecticon-ellipsis-vertical:before {
  content: "\F11E";
}

%collecticon-envelope {
  @extend %collecticon;
  content: "\F11F";
}

.collecticon-envelope:before {
  content: "\F11F";
}

%collecticon-expand-top-right {
  @extend %collecticon;
  content: "\F120";
}

.collecticon-expand-top-right:before {
  content: "\F120";
}

%collecticon-face-sad {
  @extend %collecticon;
  content: "\F121";
}

.collecticon-face-sad:before {
  content: "\F121";
}

%collecticon-file-code {
  @extend %collecticon;
  content: "\F122";
}

.collecticon-file-code:before {
  content: "\F122";
}

%collecticon-file-csv {
  @extend %collecticon;
  content: "\F123";
}

.collecticon-file-csv:before {
  content: "\F123";
}

%collecticon-file-pdf {
  @extend %collecticon;
  content: "\F124";
}

.collecticon-file-pdf:before {
  content: "\F124";
}

%collecticon-folder {
  @extend %collecticon;
  content: "\F125";
}

.collecticon-folder:before {
  content: "\F125";
}

%collecticon-globe {
  @extend %collecticon;
  content: "\F126";
}

.collecticon-globe:before {
  content: "\F126";
}

%collecticon-hamburger-menu {
  @extend %collecticon;
  content: "\F127";
}

.collecticon-hamburger-menu:before {
  content: "\F127";
}

%collecticon-home {
  @extend %collecticon;
  content: "\F128";
}

.collecticon-home:before {
  content: "\F128";
}

%collecticon-house {
  @extend %collecticon;
  content: "\F129";
}

.collecticon-house:before {
  content: "\F129";
}

%collecticon-iso-stack {
  @extend %collecticon;
  content: "\F12A";
}

.collecticon-iso-stack:before {
  content: "\F12A";
}

%collecticon-link {
  @extend %collecticon;
  content: "\F12B";
}

.collecticon-link:before {
  content: "\F12B";
}

%collecticon-lock-open {
  @extend %collecticon;
  content: "\F12C";
}

.collecticon-lock-open:before {
  content: "\F12C";
}

%collecticon-lock {
  @extend %collecticon;
  content: "\F12D";
}

.collecticon-lock:before {
  content: "\F12D";
}

%collecticon-magnifier-left {
  @extend %collecticon;
  content: "\F12E";
}

.collecticon-magnifier-left:before {
  content: "\F12E";
}

%collecticon-magnifier-right {
  @extend %collecticon;
  content: "\F12F";
}

.collecticon-magnifier-right:before {
  content: "\F12F";
}

%collecticon-marker {
  @extend %collecticon;
  content: "\F130";
}

.collecticon-marker:before {
  content: "\F130";
}

%collecticon-minus {
  @extend %collecticon;
  content: "\F131";
}

.collecticon-minus:before {
  content: "\F131";
}

%collecticon-page-label {
  @extend %collecticon;
  content: "\F132";
}

.collecticon-page-label:before {
  content: "\F132";
}

%collecticon-pencil {
  @extend %collecticon;
  content: "\F133";
}

.collecticon-pencil:before {
  content: "\F133";
}

%collecticon-plus {
  @extend %collecticon;
  content: "\F134";
}

.collecticon-plus:before {
  content: "\F134";
}

%collecticon-pop-up {
  @extend %collecticon;
  content: "\F135";
}

.collecticon-pop-up:before {
  content: "\F135";
}

%collecticon-printer {
  @extend %collecticon;
  content: "\F136";
}

.collecticon-printer:before {
  content: "\F136";
}

%collecticon-quote-left {
  @extend %collecticon;
  content: "\F137";
}

.collecticon-quote-left:before {
  content: "\F137";
}

%collecticon-rainfall {
  @extend %collecticon;
  content: "\F138";
}

.collecticon-rainfall:before {
  content: "\F138";
}

%collecticon-share-2 {
  @extend %collecticon;
  content: "\F139";
}

.collecticon-share-2:before {
  content: "\F139";
}

%collecticon-share {
  @extend %collecticon;
  content: "\F13A";
}

.collecticon-share:before {
  content: "\F13A";
}

%collecticon-sm-chevron-down {
  @extend %collecticon;
  content: "\F13B";
}

.collecticon-sm-chevron-down:before {
  content: "\F13B";
}

%collecticon-sm-chevron-left {
  @extend %collecticon;
  content: "\F13C";
}

.collecticon-sm-chevron-left:before {
  content: "\F13C";
}

%collecticon-sm-chevron-right {
  @extend %collecticon;
  content: "\F13D";
}

.collecticon-sm-chevron-right:before {
  content: "\F13D";
}

%collecticon-sm-chevron-up {
  @extend %collecticon;
  content: "\F13E";
}

.collecticon-sm-chevron-up:before {
  content: "\F13E";
}

%collecticon-sm-minus {
  @extend %collecticon;
  content: "\F13F";
}

.collecticon-sm-minus:before {
  content: "\F13F";
}

%collecticon-sm-plus {
  @extend %collecticon;
  content: "\F140";
}

.collecticon-sm-plus:before {
  content: "\F140";
}

%collecticon-sm-tick {
  @extend %collecticon;
  content: "\F141";
}

.collecticon-sm-tick:before {
  content: "\F141";
}

%collecticon-sm-triangle-down {
  @extend %collecticon;
  content: "\F142";
}

.collecticon-sm-triangle-down:before {
  content: "\F142";
}

%collecticon-sm-triangle-left {
  @extend %collecticon;
  content: "\F143";
}

.collecticon-sm-triangle-left:before {
  content: "\F143";
}

%collecticon-sm-triangle-right {
  @extend %collecticon;
  content: "\F144";
}

.collecticon-sm-triangle-right:before {
  content: "\F144";
}

%collecticon-sm-triangle-up {
  @extend %collecticon;
  content: "\F145";
}

.collecticon-sm-triangle-up:before {
  content: "\F145";
}

%collecticon-sm-xmark {
  @extend %collecticon;
  content: "\F146";
}

.collecticon-sm-xmark:before {
  content: "\F146";
}

%collecticon-sort-asc {
  @extend %collecticon;
  content: "\F147";
}

.collecticon-sort-asc:before {
  content: "\F147";
}

%collecticon-sort-desc {
  @extend %collecticon;
  content: "\F148";
}

.collecticon-sort-desc:before {
  content: "\F148";
}

%collecticon-sort-none {
  @extend %collecticon;
  content: "\F149";
}

.collecticon-sort-none:before {
  content: "\F149";
}

%collecticon-sort {
  @extend %collecticon;
  content: "\F14A";
}

.collecticon-sort:before {
  content: "\F14A";
}

%collecticon-spark {
  @extend %collecticon;
  content: "\F14B";
}

.collecticon-spark:before {
  content: "\F14B";
}

%collecticon-square--small {
  @extend %collecticon;
  content: "\F14C";
}

.collecticon-square--small:before {
  content: "\F14C";
}

%collecticon-square {
  @extend %collecticon;
  content: "\F14D";
}

.collecticon-square:before {
  content: "\F14D";
}

%collecticon-tick {
  @extend %collecticon;
  content: "\F14E";
}

.collecticon-tick:before {
  content: "\F14E";
}

%collecticon-trash-bin {
  @extend %collecticon;
  content: "\F14F";
}

.collecticon-trash-bin:before {
  content: "\F14F";
}

%collecticon-triangle-down {
  @extend %collecticon;
  content: "\F150";
}

.collecticon-triangle-down:before {
  content: "\F150";
}

%collecticon-triangle-left {
  @extend %collecticon;
  content: "\F151";
}

.collecticon-triangle-left:before {
  content: "\F151";
}

%collecticon-triangle-right {
  @extend %collecticon;
  content: "\F152";
}

.collecticon-triangle-right:before {
  content: "\F152";
}

%collecticon-triangle-up {
  @extend %collecticon;
  content: "\F153";
}

.collecticon-triangle-up:before {
  content: "\F153";
}

%collecticon-upload-2 {
  @extend %collecticon;
  content: "\F154";
}

.collecticon-upload-2:before {
  content: "\F154";
}

%collecticon-user-group {
  @extend %collecticon;
  content: "\F155";
}

.collecticon-user-group:before {
  content: "\F155";
}

%collecticon-wave-shifting {
  @extend %collecticon;
  content: "\F156";
}

.collecticon-wave-shifting:before {
  content: "\F156";
}

%collecticon-wave-smooth {
  @extend %collecticon;
  content: "\F157";
}

.collecticon-wave-smooth:before {
  content: "\F157";
}

%collecticon-wave-smoothing {
  @extend %collecticon;
  content: "\F158";
}

.collecticon-wave-smoothing:before {
  content: "\F158";
}

%collecticon-wave-square {
  @extend %collecticon;
  content: "\F159";
}

.collecticon-wave-square:before {
  content: "\F159";
}

%collecticon-xmark {
  @extend %collecticon;
  content: "\F15A";
}

.collecticon-xmark:before {
  content: "\F15A";
}
