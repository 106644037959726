.inpage__datasnapshot {
  .inpage__rightpanel {
    width: 30vw;
  }

  .singlebar__container {
    .outer-bar,
    .inner-bar {
      height: 4vh;
      .value {
        top: 1vh;
        font-weight: bold;
        font-family: "Rubik";
        font-size: 1rem;
      }
    }
  }
}

// .chart__yearlybar {
//   .popchart__chart {
//     // This is faking a roughly center vertical, with some margin,
//     // Laying out automatically is hard because of the unknown size
//     // of the legend, and that will force things badly in the svg.
//     // We really don't want this to grow without bound, and there's
//     // and we don't have a flex here to make this centered and take
//     // up all of the extra space
//     // Caps here is important, passes through sass into css.
//     margin-top: Max(1rem, calc(50vh - 250px))
//   }
// }

.legend__twocol {
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: end;
  margin-top: 1rem;

  .visx-legend-item {
    flex-basis: 45%;
  }
}

.legend__wrap {
  max-width: 100%;
  margin-top: 1rem;
  flex-wrap: wrap;

  .visx-legend-item {
    display: inline-block;
    .visx-legend-label {
      font-size: 0.875rem;
    }
  }
}
