.input-range__label-container {
  left: -50%;
  position: relative;

  .input-range__label--min & {
    left: 0;
  }

  .input-range__label--max & {
    left: 0;
  }
}
