.table thead th {
  font-size: 0.75em;
  color: $primary-color;
}

.explore__table {
  background: white;
  width: 100%;
  padding: ($global-spacing * 0.25);
  font-family: "Rubik-light", sans-serif;
  table-layout: fixed;
  border: thin $primary-color solid;

  th {
    font-weight: bold;
    font-family: Rubik, sans-serif;
    background-color: $secondary-color;
    color: $primary-color;
    table-layout: auto;
  }
  th:first-child {
    text-align: left !important;
    font-size: 1em;
  }

  td {
    text-align: right;
    border-bottom: thin $primary-color solid;
  }

  td:first-child {
    text-align: left !important;
    background: $primary-color;
    font-weight: 700;
    border-right: thin $primary-color solid;
    border-bottom: thin $secondary-color dotted;
    color: $secondary-color;
  }

  tr {
    text-align: right;
  }

  tr:nth-child(even) {
    background: $home-light-grey-color;
  }

  tr:last-child {
    border-bottom: thin solid $primary-color;
  }

  thead {
    border-bottom: 2px solid $base-color;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
