/* ==========================================================================
   Navs
   ========================================================================== */

.nav {
  color: $base-font-color;
  margin-bottom: $global-spacing;
}

.nav__link {
  @extend .heading-alt;
  font-size: 1rem;
  line-height: 1rem;
  display: inline-flex;
  padding: 0.75rem 0;
  user-select: none;
  height: 100%;
}

.nav__link {
  position: relative;
  font-weight: $base-font-bold;
  background-color: white;
  color: $primary-color;
  padding: 12px 16px;

  /* Animation */
  transition: color 0.16s ease-in-out 0s;

  &,
  &:visited {
  }

  &:hover {
    //opacity: 1;
    //color: white;
    //background-color: $primary-color;
  }

  //&::after {
  //  position: absolute;
  //  bottom: 0;
  //  left: 50%;
  //  content: '';
  //  width: 0;
  //  height: 2px;
  //  background: $base-color;
  //  transform: translate(-50%, 0);
  //
  //  /* Animation */
  //  transition: width 0.24s ease-in-out 0s;
  //}

  &--active {
    color: white;
    background-color: $primary-color;

    &::after {
      width: 100%;
    }
  }
}

/* Tablist
   ========================================================================== */

.nav__tablist {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  box-shadow: inset 0 (-$base-border-width) 0 0 $base-alpha-color;
}

.nav__tab {
  padding: 0;
  border: 1px solid $primary-color;
  flex-basis: 0;
  max-width: max-content;
  flex-grow: 1;
}

.top-supply-tabs {
  background: white;
  z-index: 10;
  .nav__tablist {
    margin: 8px;
  }
}

.global-menu__link:visited {
  color: white;
}

.global-menu__link:active,
.global-menu__link:hover {
  //background-color: transparent!important;
}
.global-menu__link--active {
  background-color: transparent !important;
  span {
    border-top: 2px solid $primary-color;
    color: $primary-color;
  }
}

.route-flex {
  display: flex;
  justify-content: space-evenly;
  position: relative;
}

.route__tab {
  border: none;
  color: $base-font-color;
  a {
    color: $base-font-color;
  }
  h4 {
    margin: 0;
    margin-left: 2rem;
    display: inline;
  }
}

.route__tab--active {
  color: $base-font-color;
  background-color: transparent;
}

.route-tabs-header {
  display: flex;
  justify-content: space-evenly;
  //padding:1.25em;
}

.route-radio {
  margin-right: 4px;
}

.route-header {
  border-right: 1px solid $base-font-color;
  padding: 1em;
  h4 {
    margin: 0;
  }
}

.route__selector {
  padding: 1em;
  padding-bottom: 0 !important;
}
