.inpage__textresults {
  flex: initial;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  box-shadow: 0 (2 * $base-border-width) 0 0 $base-alpha-color;

  @include media(medium-up) {
    padding: 0 2rem;
    flex-wrap: nowrap;
  }

  .textresults__container {
    margin: 0.25rem;
    flex-basis: 45%;
    position: relative;
    border: 1px solid $primary-color;
    height: 9em;
    padding-top: 1em;
    @include media(medium-up) {
      flex-basis: 25%;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .textresults__title {
    color: grey;
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
  }
  .textresults__value {
    color: gray;
  }
  .textresults__value_number {
    font-size: 1.75rem;
  }
  .textresults__value_units {
    font-size: 0.85rem;
  }
  .textresults__constrained {
    &:after {
      color: $success-color;
      vertical-align: super;
      font-size: 70%;
      content: "*";
      // don't mess up the line height
      line-height: 0;
    }
  }
  .textresults__footer {
    background-color: $primary-color;
    color: white;
    width: 100%;
    //border-top: 1px solid grey;
  }
  .textresults__context {
    background-color: $primary-highlight-color;
    width: 100%;
  }
}

.inpage__textresults_v {
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  flex-basis: 50%;
  flex-grow: 0;
  @include media(large-up) {
    flex-basis: 240px;
  }
}
