// The main container element
.Collapsible {
  background-color: #fff;
  max-width: 100vw;
}

//The content within the collaspable area
.Collapsible__contentInner {
  border-top: 0;
  @include media(small-up) {
    padding-right: 10px;
  }
}

//The link which when clicked opens the collapsable area
.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: $base-font-color;
  position: relative;
  padding: 10px;
  background: #fff;
  box-shadow: 0 (-$base-border-width) 0 0 $base-alpha-color;

  @include media(small-up) {
    margin-right: 12px;
  }

  &:after {
    @extend %collecticon-chevron-down;
    color: $darker-primary-color;
    position: absolute;
    right: 20px;
    top: 10px;
    display: block;
    transition: transform 300ms;
  }

  &.is-open {
    &:after {
      transform: rotateZ(180deg);
    }
  }

  &.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }
  h3 {
    margin-left: 20px;
    margin-bottom: 0px;
  }
}
