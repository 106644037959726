/* ==========================================================================
   Home: global
   ========================================================================== */

.home__heroimg {
  height: 40vh;
  @include media(medium-up) {
    height: 100vh;
    width: 50vw;
    position: fixed;
    top: 0;
    right: 0;
    background-position: right;
  }
  background-repeat: no-repeat;
  background-size: 100%;
  transition: opacity 1s ease;

  &.show {
    opacity: 1;
  }
  &.hide {
    opacity: 0;
  }
}

.inpage--home {
  @extend .antialiased;
  position: relative;
  background: $home-background-color;
  color: white;

  .inpage__header {
    height: 0;
  }

  .inpage__body {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    @include media(medium-up) {
      margin-left: 3rem;
    }

    min-height: $body-height;

    @include media(large-up) {
      min-height: $body-height;
    }
  }
}

.home-intro {
  z-index: 20;
  @include media(medium-up) {
    .fade {
      opacity: 0.6;
      transition: opacity 1s ease;
    }
  }
}

.home-intro__title {
  @include heading(2rem);

  @include media(xsmall-only) {
    @include heading(1.5rem);
  }

  @include media(large-up) {
    @include heading(3.5rem);
  }

  .highlight {
    color: $primary-color;
    font-weight: 300;
  }
}

.home-intro__lead {
  max-width: 45vw;
  font-family: "Rubik-light";

  font-size: 1.1rem;
  line-height: 1.75rem;
  font-weight: 300;
  margin-bottom: $global-spacing * 2;
  display: block;

  @include media(small-down) {
    font-size: 0.9rem;
    line-height: 1.3rem;
    max-width: 85vw;
  }

  @include media(large-up) {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  a {
    color: white;
    &:hover,
    &:visited {
      color: white;
    }
  }

  ol {
    margin-left: 0;
    @include media(medium-up) {
      margin-top: 35vh;
    }
    li {
      list-style: none;
      margin-bottom: 1rem;
      height: 70vh;
      display: block;

      @include media(small-down) {
        font-size: 0.9rem;
        line-height: 1.3rem;
        max-width: 85vw;
        .homepage-description {
          height: inherit;
          display: flex;
          flex-direction: column;
        }
        .home__heroimg {
          margin-top: auto;
        }
      }

      @include media(large-up) {
        font-size: 2rem;
        line-height: 3.25rem;
      }
    }
    li:last-child {
      height: inherit;
    }
  }
}

.cta-wrapper {
  small {
    @extend .visually-hidden;
  }

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  width: 100%;
  padding-bottom: 20vh;
  @include media(xsmall-only) {
    flex-direction: row;
  }
}

.ctab {
  @extend .button;
  margin-right: $global-spacing * 0.5;
  flex: 1;
  min-width: 10rem;
  margin: 0.5rem;

  @include media(small-up) {
    flex: 0;
    min-width: 12rem;
    margin: 1rem;
  }

  @include media(large-up) {
    min-width: 12rem;
    line-height: 2rem;
    padding: 0.5rem 2rem;
    margin-right: $global-spacing;
  }
}

/* Primary Raised Light */

.ctab--explore {
  @include button-variation($primary-color, "raised", "dark");
  text-transform: uppercase;
  font-family: "Rubik";
}

.ctab--about {
  @include button-variation($darker-primary-color, "raised", "light");
  text-transform: uppercase;
  font-family: "Rubik";
}

a.ctab.ctab--about {
  color: #002f53;
}

.home-intro__attribution {
  display: flex;
  list-style: none;
  margin: 3rem 0 0 0;
  padding: 0;
  z-index: 10;

  &.right {
    margin-top: 1rem;
    position: absolute;
    top: 0;
    right: 0;
  }

  @include media(xsmall-only) {
    margin: 2rem 0 0 0;
  }

  li {
    margin-right: 2rem;
  }

  img {
    height: 2rem;

    @include media(large-up) {
      height: 2.5rem;
    }
  }
  span {
    @extend .visually-hidden;
  }
}
