@import "input-range-variables";
@import "input-range-slider";
@import "input-range-label";
@import "input-range-label-container";
@import "input-range-track";

.input-range {
  height: 3rem;
  position: relative;
  width: 100%;
}
