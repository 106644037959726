@use "sass:math";

.inpage__rightpanel {
  box-shadow: 0 0 15px rgb(0, 0, 0, 75%);
  clip-path: inset(0px 0px 0px -15px);
  z-index: 100;
  height: $body-height;
  flex-grow: 0;
  flex-basis: 25rem;
}

.exp-map {
  @include media(small-down) {
    position: fixed;
    bottom: 7vh;
    top: calc(4rem + 4em);
    left: 0;
    right: 0;
    width: 100%;
    height: calc(50vh);
  }
}

.data-snapshot-legend {
  padding: 1em;
  padding-left: 0.5em;
  background-color: white;
  display: inline-block;
  @include media(medium-up) {
    position: absolute;
    bottom: 3em;
    left: 6em;
  }
  @include media(small-down) {
    position: fixed;
    bottom: calc(7.5vh + 3em);
    left: 3em;
  }
}

.screen-bottom-drawer-root {
  position: fixed;
  z-index: 101;
  bottom: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 33%);
  &.closed {
    display: none;
  }
}

.screen-bottom-drawer-body {
  position: fixed;
  z-index: 102;
  bottom: 0;
  height: 85vh;
  @supports (height: 85svh) {
    height: 85svh;
  }
  width: 100%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border: 1px #eee solid;
  background-color: white;
  transition: transform 0.5s;
  &.closed {
    transform: translateY(calc(100% - 4rem - 40px));
  }
}

.byosc-sidebar-inner-tab {
  overflow: auto;
  @supports (max-height: 100svh) {
    max-height: calc(85svh - 7rem);
  }
  max-height: calc(85vh - 7rem);
}

.byosc-icon-legend {
  list-style: none;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: 2rem 2rem;

  position: fixed;
  @supports(bottom: 10svh) {
    bottom: calc((4rem + ((100svh - 8rem) - 50svh - 10rem)) / 2);
  }
  bottom: calc((4rem + ((100vh - 8rem) - 50vh - 10rem)) / 2);
  height: 6rem;
  width: 100vw;
}

.byosc-section-dropdown-trigger {
  color: $primary-color;
  font-size: 16;
  text-transform: uppercase;

  background: none;
  border: 1px solid #eee;
  padding: 0 0.5rem;
  margin: 0 0.5rem;

  &::after {
    @extend %collecticon-chevron-down;
    padding-left: 0.5rem;
    padding-right: 0.25rem;
  }

  position: fixed;
  top: 4rem;
  height: 4rem;
  width: 100vw;
}

.data-snapshot-subject-dropdown-trigger {
  color: $primary-color;
  text-transform: capitalize;

  font-size: 1.25em;

  background: none;
  border: 1px solid #eee;
  padding: 0 0.5rem;
  margin: 0 0.5rem;

  &::after {
    @extend %collecticon-chevron-down;
    padding-left: 0.5rem;
    padding-right: 0.25rem;
  }
}

.sidebar-tab-body-wrapper {
  overflow: auto;
}

.econtrols.inpage__height {
  padding-top: 1rem;
}

@include media(medium-up) {
  .inpage__w {
    width: 25rem;
    max-width: 25rem;
  }
}

.screen-bottom-drawer-body.top-supply-tabs {
  padding-bottom: 1rem;
}

@include media(small-down) {
  .route__selector {
    position: fixed;
    bottom: 10vh;
    width: 100vw;
    height: 10vh;
  }
}

.top-supply-chains-route-dropdown {
  .active {
    background-color: #eee;
  }
}
.top-supply-chains-route-dropdown-trigger {
  font-size: 1.25em;
  color: black;

  background: none;
  border: none;
  padding: 0 0.5rem;
  margin: 0 0.5rem;

  &::after {
    @extend %collecticon-chevron-down;
    padding-left: 0.5rem;
    padding-right: 0.25rem;
  }
}
/* using grid here to ensure that we have header + some extrastuff
 * that can fill to the page but not farther, and then it will scroll
 */
.inpage__leftpanel {
  display: grid;
  height: 100%;
  max-height: $body-height;
  grid-template-rows: auto 1fr;

  .shadowscroll__container {
    overflow-y: hidden;
    height: 100%;
    max-height: 100%;
  }
}

.inpage__center {
  height: $body-height;
  &.has-bottom-bar {
    display: grid;
    grid-template-rows: calc($body-height - 10vh) 10vh;
  }
}

.inpage__map {
  height: 100%;
}

.container--leftpanel,
.container--rightpanel,
.container--bothpanels {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: stretch;
}

.inpage--byosc {
  //grid-template-rows: 3rem auto;
  grid-template-columns: 20vw 55vw 25vw;
  &.twocol {
    grid-template-columns: 20vw 80vw;
  }
}

.header_img_marker {
  width: 1rem;
  height: 1rem;
  vertical-align: baseline;
  margin-right: 5px;
}

.tab__sidebar {
  padding: 0 $global-spacing * 1.5;
  font-size: 0.875rem;

  .country_header {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  h2 {
    font-size: 1.125rem;
  }

  .country_flag {
    height: 2rem;
    border: 1px solid #eee;
  }

  h3 {
    font-size: 1rem;
    margin-bottom: 0;

    &.side_icon {
      display: inline-block;
    }
  }

  &.tab__overview {
    .highlight {
      background: #eee;
    }
  }

  &.scrollfix {
    // fixes electricity mix exceeding its bounds
    padding-bottom: 5rem;
  }
}

.filter__panel {
  .filter__body {
    padding: 0;

    a {
      display: block;
      color: $darker-primary-color;
      font-size: 1rem;
      padding: 0;

      &.choose__link {
        margin: 0;
        padding: 0;
        padding-left: 2em;
        width: 100%;
        &:hover {
          background: #eee;
        }
        &.selected {
          color: $primary-color;
        }
      }
    }
  }

  .close-filter {
  }

  .filter__current {
    margin-top: 0.5em;
    align-self: end;
  }

  .button--filters {
    margin: 4px;
  }

  .Collapsible__contentInner {
    padding-right: 0;
  }

  .filter__header {
    font-size: 1.125rem;
    font-weight: bold;
    font-family: "Rubik";
  }

  .filter__panel_header {
    // from .Collapsible__trigger
    padding: 10px;
  }
  .filter__group {
    @extend .Collapsible__trigger;
    &:after {
      content: "";
    }
  }

  .Collapsible__trigger {
    &.is-open {
      .filters__currentval {
        display: none;
      }
    }
  }

  .collapsible-border {
    padding-bottom: 1em;
  }

  .filter__triggertext {
    font-weight: 700;
  }

  .filters__currentval {
    margin-left: 1em;
  }

  //.button--currentval {
  //  border-bottom-left-radius: 40%;
  //  border-top-left-radius: 40%;
  //}
}

.marketshare__container {
  h4 {
    font-size: 0.875rem;
    margin-bottom: 0;
    margin-top: 0.375rem;
  }
}

.step__container {
  text-transform: uppercase;

  a {
    background-color: #e3e3e3;
    font-size: 1.125rem;
    font-family: "Rubik";
    font-weight: normal;
    color: $base-font-color;
    padding: 0.75em;
    width: 100%;
    display: block;
    height: 40px;
    border-bottom: 1px solid lighten(#e3e3e3, 4%);
  }

  .active {
    background-color: $primary-color;
    color: white;
    border-bottom: 1px solid lighten($primary-color, 4%);
  }
  width: 100%;
}

.top-supply-chains-element-dropdown-trigger {
  color: $primary-color;
  text-transform: capitalize;

  background: none;
  border: 1px solid #eee;
  padding: 0 0.5rem;
  margin: 0 0.5rem;

  &::after {
    @extend %collecticon-chevron-down;
    padding-left: 0.5rem;
    padding-right: 0.25rem;
  }
}
