/* src/tokens.css */
:root {
  --rt-color-white: #fff;
  --rt-color-dark: #222;
  --rt-color-success: #8dc572;
  --rt-color-error: #be6464;
  --rt-color-warning: #f0ad4e;
  --rt-color-info: #337ab7;
  --rt-opacity: 0.9;
}

/* esbuild-css-modules-plugin-namespace:./src/components/Tooltip/styles.module.css?esbuild-css-modules-plugin-built */
.react-tooltip__tooltip_KtSkBq {
  visibility: hidden;
  width: max-content;
  pointer-events: none;
  opacity: 0;
  will-change: opacity, visibility;
  border-radius: 3px;
  padding: 8px 16px;
  font-size: 90%;
  transition: opacity .3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
}
.react-tooltip__fixed_KtSkBq {
  position: fixed;
}
.react-tooltip__arrow_KtSkBq {
  background: inherit;
  width: 8px;
  height: 8px;
  position: absolute;
  transform: rotate(45deg);
}
.react-tooltip__no-arrow_KtSkBq {
  display: none;
}
.react-tooltip__clickable_KtSkBq {
  pointer-events: auto;
}
.react-tooltip__show_KtSkBq {
  visibility: visible;
  opacity: var(--rt-opacity);
}
.react-tooltip__dark_KtSkBq {
  background: var(--rt-color-dark);
  color: var(--rt-color-white);
}
.react-tooltip__light_KtSkBq {
  background-color: var(--rt-color-white);
  color: var(--rt-color-dark);
}
.react-tooltip__success_KtSkBq {
  background-color: var(--rt-color-success);
  color: var(--rt-color-white);
}
.react-tooltip__warning_KtSkBq {
  background-color: var(--rt-color-warning);
  color: var(--rt-color-white);
}
.react-tooltip__error_KtSkBq {
  background-color: var(--rt-color-error);
  color: var(--rt-color-white);
}
.react-tooltip__info_KtSkBq {
  background-color: var(--rt-color-info);
  color: var(--rt-color-white);
}
