/* ==========================================================================
   Documentation: global
   ========================================================================== */

.index-menu {
  max-width: 24rem;

  dt:not(:first-child) {
    margin-top: $global-spacing;
  }
}

.index-menu__link {
  display: block;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: $base-font-bold;
  padding: 0.5rem 1rem;
  border-radius: $base-border-radius;

  /* Animation */
  transition: all 0.16s ease-in-out 0s;

  &,
  &:visited {
    color: inherit;
  }

  &:hover {
    opacity: 1;
    color: $link-color;
    background-color: rgba($link-color, 0.12);
  }

  &--active {
    @extend .antialiased;

    &,
    &:visited,
    &:hover {
      color: #fff;
      background: $link-color;
    }
  }
}
