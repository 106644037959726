.dropdown__country {
  //  @extend .button, .button--primary-raised-dark;

  @extend h2;
  background: transparent;
  border: 1px solid #eee;
  padding: 0 1rem;

  &::after {
    @extend %collecticon-chevron-down;
    padding-left: 1.5rem;
    padding-right: 0.25rem;
  }
}
